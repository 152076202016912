.vertical-bar-chart {
  height: 100%;
  position: relative;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;

  .bar {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}