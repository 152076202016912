.goal-graphic {
  //{display: "flex", gap: "2em", overflowX: "auto", margin: "20px", paddingBottom: "20px"}
  display: flex;
  gap: 2em;
  overflow-x: auto;
  margin: 20px;
  padding-bottom: 20px;
}


.goal-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;
  cursor: default;
  padding: 0 20px;
  width: 75px;

  .name {
    color: #696F7C;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  .charts {
    position: relative;
    width: 100%;
    height: 255px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .hover-box {
      box-shadow: 0 1.57px 12.52px 0 rgba(32, 32, 32, 0.08);
      cursor: default;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      opacity: 1;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      min-width: 100px;
      padding: 8px 12px;

      background-color: #ffffff;
      border-radius: 8px;

      text-align: center;

      .hover-box-left {
        .hover-box-total {
          color: #0075E5
        }
      }

      .hover-box-right {
        border-left: 2px solid #E3E5E7;

        .hover-box-total {
          color: #7452B3
        }
      }

      .hover-box-left, .hover-box-right {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 5px;

        padding: 0 10px;

        .hover-box-total {
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0;
        }

        .hover-box-single-stats {
          display: flex;
          flex-direction: column;
          align-items: start;

          .stat {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 5px;
          }
        }
      }
    }
  }
}

