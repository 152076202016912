.projects-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F7F7F8;

  .projects-header {
    padding: 0 24px;
    height: 72px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #E3E5E7;

    .projects-header-title {
      font-weight: bold;
      letter-spacing: 0;
      color: #202020;
    }

  }


  .projects-grid {
    padding: 24px;

    .grid-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 4px
    }
  }
}