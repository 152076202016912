.iconify path {
  stroke-width: 1.25;
}

.spacer {
  flex: 1 0 0;
}

.monthNavContainer svg {
  fill: var(--brand);
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: calc(100% - 62px);

  &__overflow {
    height: calc(100% - 72px);
    overflow: auto;
  }

  &__fixed_height {
    display: flex;
    flex-flow: column;
    height: calc(100% - 72px);
  }

  &__wrapper {
    flex: 1;
    //max-width: calc( 100vw - 338px );
    width: 100%;
    background-color: var(--gray-90);
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.login__layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 24px;
  min-height: 100vh;
  align-items: center;

  .column {
    height: 100%;
    flex: 1 0 50%;

    &-form {
      max-width: 460px;
      display: flex;
      min-height: 100vh;
      flex-direction: column;
    }
  }

  .logo {
    font-size: 28px;
    line-height: 32px;
    font-weight: bold;
    margin-top: 24px;
    letter-spacing: 0;
    color: var(--brand);
  }

  h1 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
    color: var(--brand);
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: var(--text-color);
    margin: 16px 0 32px;
  }

  .btn {
    height: 48px;
  }

  .credits {
    font-size: 14px;
    color: var(--text-color);
    margin-bottom: 24px;

    a {
      margin-left: 6px;
    }
  }
}

.filter__row {
  display: flex;
  align-items: center;

  .form-group {
    padding-bottom: 0;
    margin: 0 6px;
  }
}

.table--report {
  width: 100%;

  td,
  th {
    padding: 8px 16px;
    border-right: 1px solid var(--input-border-color);

    &.bold {
      font-weight: bold;
      color: var(--brand);
    }
  }

  tr {
    border-bottom: 1px solid var(--input-border-color);

    &:nth-child(even) {
      background-color: #7b818f08;
    }
  }

  &__placeholder {
    height: 248px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    opacity: 0.54;

    [class^='icon-'],
    [class*=' icon-'] {
      margin-right: 12px;
    }
  }
}

.nodata__placeholder {
  height: 248px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  opacity: 0.54;

  [class^='icon-'],
  [class*=' icon-'] {
    margin-right: 12px;
  }
}

.content__wrapper {
  display: flex;
  flex-direction: column;
}

.d-flex {
  display: flex;
}

.anchors__column {
  position: sticky;
  top: 20px;
  flex: 0 0 280px;
  padding: 40px 0 0 40px;

  @media (max-width: 1080px) {
    display: none;
  }
}

.content__column {
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 144px);
}

.content__fixed_height {
  height: 100%;
}
