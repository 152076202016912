.card-header-with-line {
  border-Bottom: 1px solid var(--gray-90);
  margin: 0 -48px;
  padding: 0 48px 48px 48px;
}

.card-footer-without-line {
  text-align: right;
  margin: 0px -64px 0 -64px;
  padding: 32px 64px 0 64px;
}

.card-footer-with-line {
  text-align: right;
  margin: 32px -64px 0 -64px;
  padding: 32px 64px 0 64px;
  border-top: 1px solid var(--gray-90);
}

.retrospective-reports {
  display: flex;
  flex-direction: column;
}

.retrospective-cards {
  display: grid;
  gap: 10px 10px;
  margin: 20px;

  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 850px) {
    grid-template-columns: auto;
  }
}

.retrospective-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 14px;
  border: 1.5px solid var(--gray-90) !important;
  padding: 16px;
}

.retrospective-card-title {
  display: flex;
  gap: 10px;
  align-items: center;


  .title {
    font-size: 14px;
    font-weight: 600;
    color: rgba(32, 32, 32, 1);
    height: 19px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 30px;
    height: 30px;
  }
}

.retrospective-card-content {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.retrospective-card-data {
  display: flex;
  flex-direction: column;
  width: 50%;

  &:last-child {
    border-left: 1px solid #E3E5E7;
    padding: 0 1em;
  }
}

.retrospective-card-data-header {
  font-weight: 600;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;


  .retrospective-card-data-header-title {
    font-size: 18px;
    margin-right: 5px;
  }
}

.retrospective-card-data-body {
  font-size: 56px;
  font-weight: 400;

  .data-body-percentage{
    font-size: 32px;
  }
}

.retrospective-card-data-footer {
  font-size: 14px;
  font-weight: 400;
  color: #525661
}